{
  "max": "Max",
  "na": "N/A",
  "margin": { "label": "Margin", "depositWithdraw": "Deposit/Withdraw" },
  "footer": { "copyright": "all rights reserved" },
  "links": {
    "twitter": "Twitter",
    "telegram": "Telegram",
    "discord": "Discord",
    "medium": "Medium",
    "paper": "Whitepaper",
    "feedback": "Share Feedback",
    "featureRequest": "Feature Request",
    "contact": "Contact",
    "docs": "Docs",
    "audits": "Security & Audit Reports",
    "disclaimer": "Disclaimer",
    "terms": "Terms of Use"
  },
  "banner": { "update": "Update", "news": "News", "notice": "Notice", "ama": "AMA", "proposal": "Proposal" },
  "settings": {
    "label": "Settings",
    "approval": {
      "label": "Infinite Approval",
      "description": "Trust Hubble Contract forever",
      "tooltip": "Disabled by default, enable to trust Hubble Contract forever and token approvals will be required only once."
    },
    "confetti": {
      "label": "Confetti",
      "tooltip": "Disabled by default, enable to blow confetti on opening & closing position. This setting may impact your eye and performance while enabled."
    },
    "positionOnChart": {
      "label": "Position On Chart",
      "tooltip": "Enabled by default, shows the open position on chart for respective market."
    },
    "slippage": { "title": "Slippage (in %)", "custom": "Custom (in %)" },
    "mouse": {
      "label": "Mouse Tail",
      "tooltip": "Disabled by default, enable to trail the mouse movement with rainbow animation. This setting may impact your eye and performance while enabled."
    },
    "mood": { "label": "Select your mood", "tooltip": "Select your mood to change the color of the UI" },
    "ui": {
      "default": "Set Default Layout",
      "label": "Super Editor",
      "layout": {
        "tooltip": "Change the traders layout with shortcuts: <br /> <strong>L+1, ... L+5 </strong>",
        "label": "Choose Layout",
        "tadpole": "Tadpole",
        "sombrero": "Sombrero",
        "cartwheel": "Cartwheel",
        "cigar": "Cigar"
      },
      "color": {
        "tooltip": "Change the theme color with shortcuts: <br /> <strong>C+1, ... C+5 </strong>",
        "label": "Choose Color",
        "violet": "Vibe Violet",
        "lilac": "Super Lilac",
        "jeans": "Cool Jeans",
        "iris": "Nighty Iris"
      },
      "style": {
        "tooltip": "Change the style of UI blocks with shortcuts: <br /> <strong>S+1 & S+2 </strong>",
        "label": "Choose UI Style",
        "block": "Block",
        "normi": "Normi"
      },
      "saveFor": {
        "tooltip": "Save the layout setting for the connected address. Which means you can set different layout for different addresses.",
        "label": "Save Layout for Addresses?"
      }
    }
  },
  "onboarding": {
    "actions": { "next": "Next", "previous": "Previous", "finish": "Finish", "cancel": "Cancel", "more": "Know more" },
    "terms": {
      "title": "Acknowledge Terms",
      "accept": "Agree and Connect",
      "preTerm": "By connecting your wallet, you agree to Hubble Exchange's ",
      "termLink": "Terms of Service",
      "postTerm": "and acknowledge that you have read and understand Hubble Exchange's",
      "disclaimer": "Disclaimer."
    },
    "collaterals": {
      "title": "Deposit/Withdraw Collateral",
      "description": "Deposit supported assets as collateral from your wallet to your Margin Account to begin trading on Hubble Exchange."
    },
    "accountInfo": {
      "title": "Margin Account Information",
      "description": "An overview of your Margin Account, such as Total Deposited Collateral, Free Collateral, your account’s current leverage and other information."
    },
    "makers": {
      "title": "Market Makers",
      "description": "Provide liquidity to various markets (vAMMs) make market and earn trading fees. Makers provide virtual  liquidity in the Hubble vAMM pool (optionally on leverage). For every position opened by a trader, there is a counter position opened for all makers and they share it in the ratio of their liquidity. As a Maker, your gain is the trading fee + funding payments + liquidity mining rewards (if any)."
    },
    "hUSD": {
      "title": "Hubble USD (hUSD)",
      "description": "Deposit USDC and receive hUSD in a 1:1 ratio. You can redeem hUSD for USDC too via a withdrawal queue."
    },
    "insuranceFund": {
      "title": "Insurance Fund",
      "description": "The insurance fund acts as a backstop for bad system debt. This arises from failing to liquidate an account at the opportune moment when it has fallen below the Maintenance Margin Fraction. The insurance fund will be denominated in hUSD. To incentivize the IF LPs, they'll receive 25% of the trading fee and a 50% share in the liquidation fee alongside the liquidator."
    },
    "wallet": {
      "title": "Profile/Wallet",
      "description": "Find your Margin Account’s Balance, as well as an overview of all your activity on Hubble, including Funding Payments, Deposits, Liquidations, Trades and Liquidity Positions."
    },
    "referral": {
      "title": "Refer and Earn",
      "description": "Create Referral code now to share with your friends and earn part of trade fee from the trades they make on Hubble Exchange. Or use others code to get discount on trade fee."
    },
    "vHubble": {
      "title": "vHubble Earned Dashboard",
      "description": "Allocate 0.4% of the Total Token Supply to Liquidity Mining incentives for the AVAX-PERP vAMM for the first month of its trading. Allocate 0.2% of the Total Token Supply to Trading Incentives. Allocate 1000 HSC as incentives for Top Traders, Makers and Insurance Providers."
    },
    "layout": {
      "title": "Super Editor",
      "description": "Stylize your trading experience with Layouts, Colors, and UI Style by focusing on familiarity, accessibility, and design that adapts to your needs. Use Shortcuts L1-L5, C1-C5 and S1, S2"
    }
  },
  "errors": {
    "contract": {
      "insuranceFund_pendingObligation": "Insurance fund is currently in bad debt state. Deposits or Withdrawals at this time are disabled.",
      "hubbleReferral_noCode": "Please provide referral code",
      "hubbleReferral_shortCode": "Referral code must be at least 4 characters long",
      "hubbleReferral_codeTaken": "Given Referral Code is taken, please try new one",
      "hubbleReferral_codeExists": "You already have a referral code & cannot change or create new code",
      "hubbleReferral_codeAdded": "You already have a referral code added & cannot change the code",
      "hubbleReferral_noCodeExists": "Referral code you provided does not exists, please check the code you used",
      "hubbleReferral_ownCode": "Referral code you provided is yours & you cannot use your own code",
      "vusd_notEnoughReserve": "Cannot process withdrawals at this time: Not enough balance",
      "vusd_minAmount": "Min withdraw amount is 5 hUSD",
      "vusd_transfersDisabled": "hUSD transfers are restricted",
      "zeroAmount": "Entered Amount should be greater than 0.",
      "unbonding_too_much": "Cannot unbond more than available balance.",
      "withdrawing_more_than_unbond": "Cannot withdraw more than unbonded amount.",
      "still_unbonding": "Wait for the unbond period before withdrawing.",
      "withdraw_period_over": "Cannot withdraw, withdraw period is over.",
      "clearingHouse_blacklisted": "Your account has been blacklisted",
      "clearingHouse_margin": "Margin is below minimum allowable",
      "amm_notIgnition": "Market is not in ignition mode",
      "amm_notActive": "Market is not active",
      "amm_adding_too_less": "Liquidity amount is less than minimum 5 avax requirement",
      "amm_position_less_than_minSize": "Total position size is less than minimum 5 avax requirement",
      "amm_noLong": "New long positions are not allowed as the mark price is >5% of the index price.",
      "amm_noShort": "New short positions are not allowed as the mark price is <5% of the index price.",
      "amm_slippage": "Adjust the slippage and try again",
      "amm_leftover_liquidity_is_too_less": "Remaining liquidity is less than 5 avax minimum requirement",
      "amm_removing_very_small_liquidity": "Total position size is less than 5 avax minimum requirement",
      "amm_block_price_slippage": "Trades that move the mark price more than 1% in a single block are not allowed",
      "marginAccount_vusd_negative_balance": "Please settle hUSD balance before withdrawing",
      "userRejectedTx": "User rejected the transaction."
    },
    "notEnoughBalance": "Not enough balance",
    "notEnoughLiquidity": "Not enough liquidity",
    "sizeIsLarge": "Size is too large",
    "nullQuote": "This trade cannot be executed. Try again by adjusting the size.",
    "referral": {
      "notOwn": "You cannot refer yourself",
      "minLength": "Referral code must be at least 4 characters long",
      "used": "Code is already used, Try another one.",
      "noSpace": "Referral code cannot contain spaces",
      "maxLength": "Referral code must be at most 32 characters long"
    }
  },
  "wallet": {
    "title": "Connect Wallet",
    "connect": "Connect Another Wallet",
    "copy": "Copy Address",
    "copied": "Address Copied!",
    "myWallet": "My Wallet",
    "switchChain": "Switch Chain",
    "settings": "Settings",
    "disconnect": "Disconnect All Wallets",
    "disconnectOne": "Disconnect Active Wallet",
    "defaultUsername": "Hubbler",
    "details": {
      "balances": "Balances",
      "myPositions": "My Positions",
      "fundingPayments": "Funding Payments",
      "history": "History"
    },
    "history": { "trades": "Trades", "liquidity": "Liquidity", "margin": "Margin", "settlements": "Settlements" }
  },
  "vusd": {
    "name": "Hubble Virtual USD",
    "balance": "hUSD Balance",
    "emptyQueue": "You have no withdrawals in queue",
    "tooltip": "Available hUSD balance in your wallet",
    "process": "Process Withdrawals"
  },
  "husd": {
    "name": "Hubble Virtual USD",
    "balance": "hUSD Balance",
    "emptyQueue": "You have no withdrawals in queue",
    "tooltip": "Available hUSD balance in your wallet",
    "process": "Process Withdrawals"
  },
  "usdc": { "name": "USD Coin", "balance": "USDC Balance", "tooltip": "Available USDC balance in your wallet" },
  "swap": {
    "queue": { "amount": "Total amount", "no": "Queue No.", "total": "Total AMOUNT IN queue" },
    "removeProcess": {
      "title": "Process to withdraw USDC",
      "description": "Note that withdrawing wont be instant, there is a queue. This is because some hUSD in the system is backed by different types of collateral and not just USDC. Some traders can create hUSD when using AVAX as collateral for opening a position for example."
    }
  },
  "tradeHistory": { "loading": "Fetching trade history...", "no": "No trades found" },
  "marginHistory": { "loading": "Fetching margin history...", "no": "No margin history found" },
  "liquidityHistory": { "loading": "Fetching liquidity history...", "no": "No liquidity history found" },
  "marginLiquidationHistory": {
    "loading": "Fetching margin liquidation history...",
    "no": "No margin liquidation history found"
  },
  "spacecats": { "loading": "Getting Spacecats...", "no": "No Spacecats found" },
  "makers": {
    "chooseMarket": "Choose Market",
    "position": "Makers Position",
    "add": "Add Liquidity",
    "commit": "Commit Liquidity",
    "committing": "Committing Liquidity",
    "adding": "Adding Liquidity",
    "remove": "Remove Liquidity",
    "removing": "Removing Liquidity",
    "unbondLq": "Unbond Liquidity",
    "unbond": "Start Unbonding",
    "withdraw": "Withdraw Liquidity",
    "unbonding": "Unbonding",
    "lpShares": {
      "label": "LP Shares",
      "tooltip": {
        "main": "You have {lp} LP shares currently",
        "unbondPeriod": "Liquidity LP-Share amount you can withdraw after unbond period.",
        "withdrawPeriod": "Liquidity LP-Share amount you can withdraw from this period."
      }
    },
    "apy": "APR",
    "rewardsTooltip": "Calculated APY assumes a token price of $0.04 at seed round valuation of $40 million. Valuation may have fluctuated significantly since the seed round valuation date. Hubble Exchange disclaims the accuracy of the figure provided above and provides this figure as general information. Any reliance on the figure provided is at your own risk and is provided as-is, without any representations or warranties of any kind by Hubble Exchange. <br /> <br /> This APY does not include the risk of Impermanent Loss, funding payments and yield from trading fees.",
    "vHubbleTooltip": "Assumes a token price of $0.04 at seed round valuation of $40 million. Valuation may have fluctuated significantly since the seed round valuation date. Hubble Exchange disclaims the accuracy of the figure provided above and provides this figure as general information. Any reliance on the figure provided is at your own risk and is provided as-is, without any representations or warranties of any kind by Hubble Exchange.",
    "impermanent": {
      "position": {
        "label": "Impermanent Position",
        "tooltip": "For every position opened by a trader, there is a counter position opened for all makers. For e.g. if a trader takes 5 ETH long, all makers currently in the pool will share a 5 ETH short position."
      },
      "impermanentLoss": "Impermanent Loss",
      "vHubble": "vHUBBLE EARNED"
    },
    "removeProcess": {
      "title": "Process to Remove Liquidity",
      "description": "When the AMM is active, it takes 48-72 hours to be able to withdraw your committed/added liquidity. The LP has to first unbond and wait out the unbonding period. After the unbonding period, LP has to withdraw within the 24 hours withdrawal period."
    },
    "ignition": {
      "title": "Ignition Period",
      "description": "The ignition period is the time when it’s possible to add liquidity to the AMM but the trading has not yet begun. When a sufficient pre-determined liquidity goal is reached, system maintainers will make a tx that will add equal parts of USD and base asset liquidity to the AMM at the current price. At this time, trading can begin. Liquidity commitment can be increased but not reduced.",
      "committedLiquidity": { "label": "Committed Liquidity", "tooltip": "Committed Liquidity by you" },
      "totalLiquidity": "Total Committed Liquidity"
    },
    "poolPercentage": "Pool Percentage",
    "currentLiquidity": {
      "label": "Current Liquidity",
      "tooltip": "Breakdown of your liquidity in the pool. If you see more of the asset than originally deposited, it means you are long that asset; short if Less."
    },
    "totalLiquidity": "Total Liquidity",
    "accountLeverage": {
      "label": "Account Leverage",
      "tooltip": {
        "add": "Total Account Leverage after adding liquidity",
        "remove": "Total Account Leverage after removing liquidity",
        "commit": "Total Account Leverage after committing liquidity"
      }
    },
    "estimatedLiquidationPrice": "Estimated Liquidation Price",
    "time": "Time Remaining",
    "unbondPeriod": {
      "label": "Unbond Period",
      "tooltip": "Unbond Period is 2-3 days period after start removing liquidity from market once this period is over you can withdraw within 1 day."
    },
    "withdrawPeriod": {
      "label": "Withdraw Period",
      "tooltip": "After the unbond period ends, you can withdraw full or partial amount you have unbonded within 1 day/24 hours. Once the withdraw period is over and you have not withdrawn your amount, your amount will be added back."
    }
  },
  "unbondWarning": "You already started Un-Bonding {amount}. Un-Bonding again will reset the amount and time of Un-Bonding. Please wait and withdraw the current Un-Bonded amount or Continue.",
  "insuranceFund": {
    "definition": "The insurance fund acts as a backstop for bad system debt. This arises from failing to liquidate an account at the opportune moment when it has fallen below the Maintenance Margin Fraction. The insurance fund will be denominated in hUSD. To incentivize the IF LPs, they'll receive 25% of the trading fee and a 50% share in the liquidation fee alongside the liquidator.",
    "apy": "APY",
    "totalBalance": "Total Pool Balance",
    "more": "More On Insurance Fund",
    "balance": {
      "label": "Insurance Fund Balance",
      "tooltip": "Provided liquidity to Insurance Fund in Hubble Insurance Fund Token(HIF)"
    },
    "walletBalance": { "label": "Wallet Balance", "tooltip": "Available hUSD balance in your wallet" },
    "deposit": "Deposit Now",
    "withdraw": "Withdraw Now",
    "time": "Time remaining",
    "unbondPeriod": {
      "label": "Unbond Period",
      "tooltip": "Unbond Period is 1-2 days period after start removing funds from Insurance Fund once this period is over you can withdraw within 1 day."
    },
    "withdrawPeriod": {
      "label": "Withdraw Period",
      "tooltip": "After the unbond period ends, you can withdraw full or partial amount you have unbonded within 1 day/24 hours. Once the withdraw period is over and you have not withdrawn your amount, your amount will be added back."
    },
    "hif": {
      "label": "HIF Token",
      "labels": "HIF Tokens",
      "unbond": "Hubble Insurance Fund token amount you can withdraw after unbond period.",
      "withdraw": "Hubble Insurance Fund tokens amount you can withdraw in this period."
    },
    "removeProcess": {
      "title": "Process to Remove Funds",
      "description": "It takes 48-72 hours to be able to withdraw your deposited insurance funds. The funds has to first unbond and wait out the unbonding period. After the unbonding period, funds has to withdraw within the 24 hours withdrawal period."
    }
  },
  "referral": {
    "title": "Referral",
    "referTitle": "Refer & Earn",
    "useTitle": "Use & Save",
    "myTitle": "My Referral Code",
    "description": "Create Referral Code or Use and Start Earning & Saving 🚀",
    "downloadImg": "Download Referral Image",
    "sizeImg": "Choose Image Type",
    "use": {
      "label": "Use Code",
      "labelTooltip": "Use Referral code to start saving on trading fee.",
      "have": "You have a used code",
      "haveTooltip": "You are already saving on trading fee.",
      "note": "Note: you cannot change the code once you use it.",
      "orHave": "Or You Have a Code"
    },
    "create": {
      "label": "Create Code",
      "labelTooltip": "Create Referral code and share it and earn part of the trade fee.",
      "note": "Note: you cannot change or recreate the code once created.",
      "haveNote": "Note: share as much as you can to earn more.",
      "have": "Woah! You have a Code",
      "haveTooltip": "Download image and/or share it on twitter and earn part of the trade fee from trader who used your code.",
      "action": "Create Your Code"
    },
    "feeEarned": {
      "label": "Total fee earned",
      "tooltip": "Total fee earned from the trades of traders who used your code."
    },
    "feeSaving": {
      "label": "Fees Saving",
      "labelTrade": "Saving Trade Fee",
      "labelTooltip": "Saving trade fee by using referral code on each trade."
    },
    "useCode": {
      "label": "Use referral code",
      "tooltip": "You have referral code? Click on use now to start saving on trading fee."
    },
    "usedCode": { "label": "Referral Code used", "tooltip": "Referral Code used cannot be changed." },
    "usedBy": { "label": "Code used by", "tooltip": "Number of traders used your referral code" },
    "saving": {
      "label": "Saving Trade Fee",
      "tooltip": "Saving trade fee by using referral code on each trade.",
      "action": "Start Saving Now"
    },
    "actions": { "use": "Use Now", "create": "Create Now", "copyUrl": "Copy URL" }
  },
  "vHubble": {
    "label": "vHubble",
    "total": "TOTAL vHUBBLE EARNED",
    "totalTooltip": "Total token supply is 1B. Each vHubble will be exchanged for Hubble Exchange Tokens in a 1:1 ratio in the future. The following vesting will be applied to vHubble owners: <ul class=\"pt-3\"> <li class=\"py-1\"> - 70% of all vHubble will be unlocked at the Token Generation Event (TGE).</li> <li class=\"py-1\"> - 15% of vHubble will be vested over 6 months, starting TGE.</li> <li class=\"py-1\"> - 15% of vHubble will be vested in the form of Key Performance Indicator (KPI) options, executable when cumulative trading volume is over $5B USD.</li> </ul>",
    "maker": "Earned as Maker",
    "trader": "Earned as Trader",
    "interest": "Earned for Open Interest",
    "insurance": "Earned as Insurance funder",
    "totalHsc": "Total Space Cats earned",
    "totalHscAbbr": "Total Hubble Space Cats(HSCs) Earned",
    "totalHscTooltip": "Makers, Traders and Insurance funder's HSC rewards",
    "readMore": {
      "label": "Read more about Hubble Liquidity Mining Incentives",
      "description": "This proposals seeks to: Allocate 0.4% of the Total Token Supply to Liquidity Mining incentives for the AVAX-PERP vAMM for the first month of its trading. Allocate 0.2% of the Total Token Supply to Trading Incentives. Allocate 1000 HSC as incentives for Top Traders, Makers and Insurance Providers. The vHubble System Motivation For each prot..."
    }
  },
  "fundingPayments": {
    "label": "Funding Payments",
    "loading": "Fetching funding payments...",
    "no": "No funding payment found",
    "tooltip": "Funding is a series of continuous payments that are exchanged between longs and the shorts. When a Perpetual Swap contract price is higher than the spot price i.e. it is trading at a premium, longs pay funding to shorts. Conversely, when the contract price is lower than the spot price i.e. it is trading at a discount, shorts pay funding to longs."
  },
  "balance": {
    "label": "Balance",
    "margin": "Margin Balance",
    "usd": "USD Value",
    "withdrawable": "Margin Withdrawable",
    "addTokenToWallet": "Click here to add token to the connected wallet if it supports"
  },
  "recentTrades": { "label": "Recent Trades", "loading": "Fetching recent trades", "no": "No recent trades found" },
  "position": {
    "overview": "Position Overview",
    "market": "Market",
    "long": "Long",
    "short": "Short",
    "size": "Size",
    "openNotional": "Open Notional",
    "unrealizedPnl": "Unrealized PNL",
    "estLPrice": "Est. Liq. Price",
    "avgOpenPrice": "Avg. Open Price",
    "open": "Open Position | Open Positions",
    "showAll": "Show All Positions",
    "loading": "Loading Positions",
    "no": "No Position Found"
  },
  "closePosition": {
    "title": "Close Position Overview",
    "size": "Size",
    "price": "Price",
    "totalUsd": "Total USD",
    "fees": "Fees",
    "txnLink": "Txn Link",
    "profit": "Profit",
    "loss": "Loss"
  },
  "market": {
    "label": "Market",
    "inactive": {
      "label": "Market is inactive",
      "description": "Market is currently inactive. Please check other markets or check back later."
    },
    "ignition": {
      "label": "Ignition Period",
      "title": "Market is in ignition",
      "description": "The ignition period is the time when it’s possible to add liquidity to the AMM but the trading has not yet begun. When a sufficient pre-determined liquidity goal is reached, system maintainers will make a tx that will add equal parts of USD and base asset liquidity to the AMM at the current price. At this time, trading can begin. Liquidity commitment can be increased but not reduced."
    }
  },
  "totalFee": {
    "label": "Total Fee",
    "tooltip": "The total fee is charged in 2 components - AMM Fee (included in open notional) and IF Fee (charged in addition)",
    "title": "Total Fee is {fee} hUSD",
    "formula": "Total Fee = ({ammFee}) AMM Fee + ({tradeFee}) Insurance fund (IF) fee"
  },
  "page": { "next": "Next Page", "prev": "Previous Page" },
  "seized": { "coin": "Seized Coin", "amount": "Seized Amount" },
  "side": "Side",
  "orderType": "Order Type",
  "repaid": "Repaid",
  "size": "Size",
  "price": "Price",
  "total": "Total",
  "fee": "Fee",
  "or": "Or",
  "realizedPnl": "Realized PnL",
  "type": "Type",
  "share": "Share",
  "coin": "Coin",
  "txnLink": "Txn Link",
  "amount": "Amount",
  "asset": "Asset",
  "time": "Time",
  "virtual": "Virtual",
  "visit": "Visit",
  "traders": "Traders",
  "square": "Square",
  "rectangle": "Rectangle",
  "add": "Add",
  "makerPayment": "Maker Payment",
  "takerPayment": "Taker Payment",
  "rate": "Rate",
  "readGuide": "Read Guide",
  "warning": "Warning",
  "wait": "Wait",
  "continue": "Continue",
  "yes": "Yes",
  "no": "No",
  "copied": "Copied",
  "yourCollateral": "Your Collateral",
  "SwapCollateral": "Swap Your Collateral",
  "previewImg": "Preview Image",
  "conversionRate": { "label": "Conversion Rate", "short": "Conv. Rate" },
  "inputs": {
    "mint": "Mint Amount",
    "redeem": "Redeem Amount",
    "deposit": "Deposit Amount",
    "withdraw": "Withdraw Amount",
    "send": "Send",
    "receive": "Receive"
  },
  "account": {
    "label": "Account Info",
    "labelFull": "Account Information",
    "totalCollateral": "Total Collateral",
    "freeMargin": { "label": "Free Margin", "tooltip": "Free margin in your Margin Account" },
    "leverage": "Leverage",
    "maxLeverage": "Max Leverage",
    "marginRatio": "Margin Ratio",
    "unrealizedPNL": "Unrealized PNL",
    "totalNotionalPosition": "Total Notional Position",
    "marginFraction": "Margin Fraction",
    "liquidationLeverage": { "label": "Liq. Leverage", "tooltip": "Liquidation Leverage" },
    "liquidationMarginFraction": { "label": "Liq. Margin Fraction", "tooltip": "Liquidation Margin Fraction" },
    "chooseYourPFP": "Choose your PFP"
  },
  "leverage": { "label": "Account Leverage", "tooltip": "Total Account Leverage after opening position" },
  "estLPrice": {
    "label": "Estimated Liquidation Price",
    "tooltip": "Estimated Liquidation Price after opening position"
  },
  "marketInfo": {
    "label": "Market Info",
    "labelFull": "Market Information",
    "indexPrice": "Index Price",
    "markPrice": "Mark Price",
    "openInterest": "Open Interest",
    "twenty4Change": "24 Hour Change",
    "twenty4Volume": "24 Hour Volume",
    "predictedFundingRate": { "label": "P. Funding Rate", "tooltip": "Predicted Funding Rate" }
  },
  "learn": "Learn more",
  "snacks": {
    "erc20": {
      "approve": "approve amount",
      "ableToAdd": "{token} successfully added to wallet",
      "unableToAdd": "Failed to add {token} to the wallet"
    },
    "husd": {
      "process": "process withdrawals",
      "mint": "mint hUSD",
      "mintDeposit": "mint hUSD and deposit to margin",
      "withdraw": "withdraw hUSD"
    },
    "margin": { "deposit": "deposit to margin", "withdraw": "withdraw from margin" },
    "insuranceFund": {
      "deposit": "deposit to insurance fund",
      "unbond": "start unbonding from insurance fund",
      "withdraw": "withdraw from insurance fund"
    },
    "maker": {
      "add": "add liquidity",
      "unbond": "start unbonding",
      "remove": "remove liquidity",
      "commit": "commit liquidity"
    },
    "referral": {
      "create": "create referral code",
      "use": "use referral code",
      "copyCode": "Referral Code copied to clipboard",
      "copyUrl": "Referral URL copied to clipboard"
    }
  },
  "tweets": {
    "referral": "Trade Perpetual Futures on Avalanche. Redeem this code to receive a 10% trading fee discount on {'@'}hubbleexchange"
  },
  "actions": {
    "buy": { "label": "Buy", "loading": "Opening Position..." },
    "sell": { "label": "Sell", "loading": "Opening Position..." },
    "approve": { "label": "Approve", "loading": "Approving..." },
    "withdraw": { "label": "Withdraw", "loading": "Withdrawing..." },
    "deposit": { "label": "Deposit", "loading": "Depositing..." },
    "unbond": { "label": "Start Unbonding", "loading": "Starting to Unbond..." },
    "mint": { "label": "Mint", "loading": "Minting..." },
    "mintDeposit": { "label": "Mint & Deposit", "loading": "Minting & Depositing..." },
    "mintSettle": { "label": "Mint & Settle", "loading": "Minting & Depositing..." },
    "redeem": { "label": "Redeem", "loading": "Redeeming..." },
    "remove": { "label": "Remove", "loading": "Removing..." },
    "removeLq": { "label": "Remove Liquidity", "loading": "Removing Liquidity..." },
    "withdrawLq": { "label": "Withdraw Liquidity", "loading": "Withdrawing Liquidity..." },
    "claim": { "label": "Claim", "loading": "Claiming..." },
    "openPosition": { "label": "Open Position", "loading": "Opening Position..." },
    "closePosition": { "label": "Close Position", "loading": "Closing Position..." },
    "useCode": { "label": "Use Code", "loading": "Using Code..." },
    "createCode": { "label": "Create Code", "loading": "Creating Code..." },
    "downloadImg": { "label": "Download Image", "loading": "Downloading Image..." },
    "swap": { "label": "Swap Token", "loading": "Swapping..." },
    "checkPrice": { "label": "Check Price", "loading": "Checking Price..." },
    "refreshPrice": { "label": "Refresh Price", "loading": "Refreshing Price..." },
    "twitter": "Share on Twitter",
    "close": "Close",
    "explorer": "View on Explorer",
    "quote": "Getting Quote..."
  }
}
